import validate from "/node_modules/nuxt/dist/pages/runtime/validate.js";
import cache_45buster_45global from "/app/middleware/cache-buster.global.ts";
import subscription_45global from "/app/middleware/subscription.global.ts";
export const globalMiddleware = [
  validate,
  cache_45buster_45global,
  subscription_45global
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts"),
  "check-reset-token": () => import("/app/middleware/check-reset-token.ts"),
  "no-auth": () => import("/app/middleware/no-auth.ts"),
  "team-owner": () => import("/app/middleware/team-owner.ts")
}