export function objKeysToCamelCase(obj: Record<string, any>) {
  return objSwitchCase(obj, toCamelCase);
}

export function objKeysToSnakeCase(obj: Record<string, any>) {
  return objSwitchCase(obj, toSnakeCase);
}

// FIXME: fast-made non-recursive solution
function objSwitchCase(
  obj: Record<string, any>,
  switchStrCaseFn: (key: string) => string
) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    const updKey = switchStrCaseFn(key);
    Object.assign(newObj, { [updKey]: obj[key] });
  });
  return newObj;
}

function toSnakeCase(key: string): string {
  const keyCharsArr = key.split("").reduce((acc: string[], char: string) => {
    if (char == char.toUpperCase()) {
      acc.push(`_${char.toLowerCase()}`);
      return acc;
    }
    acc.push(char);
    return acc;
  }, []);
  return keyCharsArr.join("");
}

function toCamelCase(key: string): string {
  if (!key.length) return key;
  const keywordsArr = key.split("_");
  if (keywordsArr.length < 2) return key;

  let resultArr = [keywordsArr[0]];

  let i = 1;
  while (i < keywordsArr.length) {
    const keyword = keywordsArr[i];
    const capitalizedWord =
      keyword.charAt(0).toUpperCase() + keyword.slice(1, keyword.length);
    resultArr.push(capitalizedWord);
    i++;
  }
  return resultArr.join("");
}
