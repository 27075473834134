import { ApiService, QueryParams } from './api.service'

interface UsageFolders {
    data: { value: string; label: string }[]
}

export interface UsageFuntions {
    data: { value: string; label: string }[]
}

export interface UsageReportsData {
    created_at: string
    member: string
    function: string
    folder: string
    credits: number
}

export interface IProductivityChargeOutRate {
    charge_out_rate: number
}

export interface IProductivityData {
    minSavedTotal: number
    productivity: number
    chargeOutRate: number
}

export default class UsageService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public getData = async (
        query: QueryParams
    ): Promise<{ data: UsageReportsData[] }> => {
        return await this.get('reporting/fetch_data', query)
    }

    public getFolders = async (): Promise<UsageFolders> => {
        return await this.get('reporting/fetch_folders')
    }

    public getFunctions = async (): Promise<UsageFuntions> => {
        return await this.get('reporting/fetch_functions')
    }

    public getProductivity = async (query: QueryParams): Promise<IProductivityData> => {
        return await this.get(`cost_config/productivity`, query)
    }

    public updateProductivityChargeOutRate = async (
        data: IProductivityChargeOutRate
    ) => {
        return await this.put(`cost_config/charge-out-rate`, data)
    }
}
