import { IPlan } from '@/services/plans.service'

const pricingFeatures: IPlan[] = [
    {
        id: 3,
        name: 'Legal Practice',
        monthly_price_id: 'price_1P0iU5Feej5Z08mupWiQ0rVc',
        yearly_price_id: 'price_1PafaBFeej5Z08muIitmgiFd',
        monthly_cost: 550,
        yearly_cost: 5280,
        original_yearly: 6600,
        features: [
            {
                name: 'Unlimited Users',
                id: 'unlimited_users',
                available: true,
                description:
                    'Invite all your colleagues or contractors to work with you from the one subscription. Team billing allows you to connect other team member accounts to a single billing account so that you can manage billing and usage in one place.',
            },
            {
                name: 'Document Review',
                id: 'document_review',
                available: true,
                description:
                    'Have your AI assistant instantly review documents for Ambiguities, Complex Language, Conflicting Terms, Placeholders, Inconsistencies, Missing Clauses, Non-Standard Clauses, Points to Negotiate, Undefined Terms.',
            },
            {
                name: 'Discuss Document',
                id: 'discuss_document',
                available: true,
                description:
                    'Discuss document with your AI using chat or talk to it like you were a colleague to play devils advocate, brainstorm ideas or just do a quick sanity check. Ask it to draft emails for you based on the current document or anything else you can imagine.',
            },
            {
                name: 'Draft Documents',
                id: 'draft_documents',
                available: true,
                description:
                    'Have your AI assistant, draft or edit clauses and definitions, make sections shorter or longer, simplify or complexify language, summarise passages, this key, takeaways, explain complex language, or draft anything under your instructions via voice or text.',
            },
            {
                name: 'Research Assistant',
                id: 'research_assistant',
                available: true,
                description:
                    'Your research assistant is trained on Australian law and precedence and consistently updated every week with the latest cases and information. Ask it to conduct research and bring you citations instantly.',
            },
            {
                name: 'Team Collaboration',
                id: 'team_collaboration',
                available: true,
                description:
                    'Share documents with colleagues and collaborate in real time.',
            },
            {
                name: 'Actions',
                id: 'actions',
                available: true,
                description:
                    'Your assistant can dictate for you, prepare meeting minutes, create tasks, and draft emails to clients or paralegals with a single click.',
            },
            {
                name: 'Task Management',
                id: 'task_management',
                available: true,
                description:
                    'Create tasks or reminders for yourself, and colleagues with two clicks of a button.',
            },
            {
                name: 'Document Storage',
                id: 'document_storage',
                available: true,
                description:
                    'Securely store all your documents, and share them with colleagues.',
            },
            {
                name: 'Insert Templates',
                id: 'insert_templates',
                available: true,
                description:
                    'Save templates of clauses, definitions, or anything else quickly, then add them to the workflow with one click.',
            },
            {
                name: 'Multi-Doc Review',
                id: 'multi_doc_review',
                available: true,
                description:
                    'Analyse multiple documents Simultaneously, have your AI assistant review a collection of documents and find conflicting clauses or definitions between them, inconsistencies, mismatching, details, missing, references, and chronological order and timeline.',
            },
            {
                name: 'Multi-Doc Discuss',
                id: 'multi_doc_discuss',
                available: true,
                description:
                    'Analyse multiple documents Simultaneously, discuss document with your AI using chat or talk to it like you were a colleague to play devils advocate, brainstorm ideas or just do a quick sanity check. Ask it to draft emails for you based on the current document or anything else you can imagine.',
            },
            {
                name: 'Multi-Doc Drafting',
                id: 'multi_doc_review',
                available: true,
                description:
                    'Analyse multiple documents Simultaneously, have your AI assistant, draft or edit clauses and definitions, draft full document based on the text selected, make sections shorter or longer, simplify or complexify language, summarise passages, this key, takeaways, explain complex language, or draft anything under your instructions via voice or text.',
            },
            {
                name: 'Team Billing',
                id: 'team_billing',
                available: true,
                description:
                    'Team billing allows you to connect other team member accounts to a single billing account so that you can manage billing and usage in one place.',
            },
            {
                name: 'Dictate Everything',
                id: 'dictate_everything',
                available: true,
                description:
                    'Click to talk on all functions and have it transcribed for you in real time. ',
            },
            {
                name: 'Auto Draft Full Documents',
                id: 'auto_draft_full_documents',
                available: true,
                description:
                    'Either draft a short brief or have your client fill in a questionnaire and the AI will draft a full document outline for you to approve, it will then draft the full document up to 100 pages long. Works on any type of document from pleadings, to wills, binding financial agreements, contracts. We really mean all types of documents.',
            },
            {
                name: 'External Document Sharing',
                id: 'external_document_sharing',
                available: true,
                description:
                    'Instead of downloading a file and sending it on email to a client, simply share a private, secure link to the document with your client and have them edit with track changes, comment or just view it.',
            },
            {
                name: 'Instant Transcribe',
                id: 'instant_transcribe',
                available: true,
                description:
                    'Upload audio or video files and receive accurate transcriptions back in seconds. ',
            },
            {
                name: 'Precedent Manager',
                id: 'precedent_manager',
                available: true,
                description:
                    'Save full documents, special clauses, or any other text to your library and share it with the team so you are all consistent across the firm.',
            },
            {
                name: 'Live Customer Support ',
                id: 'live_customer_support',
                available: true,
                description:
                    'Live customer support online from 8am to 8pm AEST.',
            },
            {
                name: '1 On 1 Training',
                id: '1_on_1_training',
                available: true,
                description:
                    'Live customised training to suit your way of doing things. ',
            },
        ],
    },
    {
        id: 2,
        name: 'Premium',
        monthly_price_id: 'price_1P0iuhFeej5Z08muvSPaobWs',
        yearly_price_id: 'price_1PafYrFeej5Z08mu9dfTunjn',
        monthly_cost: 198,
        yearly_cost: 1900,
        original_yearly: 2376,
        features: [
            {
                name: 'Unlimited Users',
                id: 'unlimited_users',
                available: true,
                description:
                    'Invite all your colleagues or contractors to work with you from the one subscription. Team billing allows you to connect other team member accounts to a single billing account so that you can manage billing and usage in one place.',
            },
            {
                name: 'Document Review',
                id: 'document_review',
                available: true,
                description:
                    'Have your AI assistant instantly review documents for Ambiguities, Complex Language, Conflicting Terms, Placeholders, Inconsistencies, Missing Clauses, Non-Standard Clauses, Points to Negotiate, Undefined Terms.',
            },
            {
                name: 'Discuss Document',
                id: 'discuss_document',
                available: true,
                description:
                    'Discuss document with your AI using chat or talk to it like you were a colleague to play devils advocate, brainstorm ideas or just do a quick sanity check. Ask it to draft emails for you based on the current document or anything else you can imagine.',
            },
            {
                name: 'Draft Documents',
                id: 'draft_documents',
                available: true,
                description:
                    'Have your AI assistant, draft or edit clauses and definitions, make sections shorter or longer, simplify or complexify language, summarise passages, this key, takeaways, explain complex language, or draft anything under your instructions via voice or text.',
            },
            {
                name: 'Research Assistant',
                id: 'research_assistant',
                available: true,
                description:
                    'Your research assistant is trained on Australian law and precedence and consistently updated every week with the latest cases and information. Ask it to conduct research and bring you citations instantly.',
            },
            {
                name: 'Team Collaboration',
                id: 'team_collaboration',
                available: true,
                description:
                    'Share documents with colleagues and collaborate in real time.',
            },
            {
                name: 'Actions',
                id: 'actions',
                available: true,
                description:
                    'Your assistant can dictate for you, prepare meeting minutes, create tasks, and draft emails to clients or paralegals with a single click.',
            },
            {
                name: 'Task Management',
                id: 'task_management',
                available: true,
                description:
                    'Create tasks or reminders for yourself, and colleagues with two clicks of a button.',
            },
            {
                name: 'Document Storage',
                id: 'document_storage',
                available: true,
                description:
                    'Securely store all your documents, and share them with colleagues.',
            },
            {
                name: 'Insert Templates',
                id: 'insert_templates',
                available: true,
                description:
                    'Save templates of clauses, definitions, or anything else quickly, then add them to the workflow with one click.',
            },
            {
                name: 'Multi-Doc Review',
                id: 'multi_doc_review',
                available: true,
                description:
                    'Analyse multiple documents Simultaneously, have your AI assistant review a collection of documents and find conflicting clauses or definitions between them, inconsistencies, mismatching, details, missing, references, and chronological order and timeline.',
            },
            {
                name: 'Multi-Doc Discuss',
                id: 'multi_doc_discuss',
                available: true,
                description:
                    'Analyse multiple documents Simultaneously, discuss document with your AI using chat or talk to it like you were a colleague to play devils advocate, brainstorm ideas or just do a quick sanity check. Ask it to draft emails for you based on the current document or anything else you can imagine.',
            },
            {
                name: 'Multi-Doc Drafting',
                id: 'multi_doc_review',
                available: true,
                description:
                    'Analyse multiple documents Simultaneously, have your AI assistant, draft or edit clauses and definitions, draft full document based on the text selected, make sections shorter or longer, simplify or complexify language, summarise passages, this key, takeaways, explain complex language, or draft anything under your instructions via voice or text.',
            },
            {
                name: 'Team Billing',
                id: 'team_billing',
                available: true,
                description:
                    'Team billing allows you to connect other team member accounts to a single billing account so that you can manage billing and usage in one place.',
            },
            {
                name: 'Dictate Everything',
                id: 'dictate_everything',
                available: true,
                description:
                    'Click to talk on all functions and have it transcribed for you in real time. ',
            },
            {
                name: 'Auto Draft Full Documents',
                id: 'auto_draft_full_documents',
                available: true,
                description:
                    'Either draft a short brief or have your client fill in a questionnaire and the AI will draft a full document outline for you to approve, it will then draft the full document up to 100 pages long. Works on any type of document from pleadings, to wills, binding financial agreements, contracts. We really mean all types of documents.',
            },
            {
                name: 'External Document Sharing',
                id: 'external_document_sharing',
                available: true,
                description:
                    'Instead of downloading a file and sending it on email to a client, simply share a private, secure link to the document with your client and have them edit with track changes, comment or just view it.',
            },
            {
                name: 'Instant Transcribe',
                id: 'instant_transcribe',
                available: true,
                description:
                    'Upload audio or video files and receive accurate transcriptions back in seconds. ',
            },
            {
                name: 'Precedent Manager',
                id: 'precedent_manager',
                available: true,
                description:
                    'Save full documents, special clauses, or any other text to your library and share it with the team so you are all consistent across the firm.',
            },
            {
                name: 'Live Customer Support ',
                id: 'live_customer_support',
                available: true,
                description:
                    'Live customer support online from 8am to 8pm AEST.',
            },
            {
                name: '1 On 1 Training',
                id: '1_on_1_training',
                available: true,
                description:
                    'Live customised training to suit your way of doing things. ',
            },
        ],
    },
    {
        id: 1,
        name: 'Starter',
        monthly_price_id: 'price_1P0ivvFeej5Z08mu9Vt3Oj8s',
        yearly_price_id: 'price_1PafY7Feej5Z08munzBxPlMA',
        monthly_cost: 99,
        yearly_cost: 950,
        original_yearly: 1188,
        features: [
            {
                name: 'Unlimited Users',
                id: 'unlimited_users',
                available: true,
                description:
                    'Invite all your colleagues or contractors to work with you from the one subscription. Team billing allows you to connect other team member accounts to a single billing account so that you can manage billing and usage in one place.',
            },
            {
                name: 'Document Review',
                id: 'document_review',
                available: true,
                description:
                    'Have your AI assistant instantly review documents for Ambiguities, Complex Language, Conflicting Terms, Placeholders, Inconsistencies, Missing Clauses, Non-Standard Clauses, Points to Negotiate, Undefined Terms.  ',
            },
            {
                name: 'Discuss Document',
                id: 'discuss_document',
                available: true,
                description:
                    'Discuss document with your AI using chat or talk to it like you were a colleague to play devils advocate, brainstorm ideas or just do a quick sanity check. Ask it to draft emails for you based on the current document or anything else you can imagine.',
            },
            {
                name: 'Draft Documents',
                id: 'draft_documents',
                available: true,
                description:
                    'Have your AI assistant, draft or edit clauses and definitions, make sections shorter or longer, simplify or complexify language, summarise passages, this key, takeaways, explain complex language, or draft anything under your instructions via voice or text.',
            },
            {
                name: 'Research Assistant',
                id: 'research_assistant',
                available: false,
                description:
                    'Your research assistant is trained on Australian law and precedence and consistently updated every week with the latest cases and information. Ask it to conduct research and bring you citations instantly.',
            },
            {
                name: 'Team Collaboration',
                id: 'team_collaboration',
                available: true,
                description:
                    'Share documents with colleagues and collaborate in real time.',
            },
            {
                name: 'Actions',
                id: 'actions',
                available: true,
                description:
                    'Your assistant can dictate for you, prepare meeting minutes, create tasks, and draft emails to clients or paralegals with a single click.',
            },
            {
                name: 'Task Management',
                id: 'task_management',
                available: false,
                description:
                    'Create tasks or reminders for yourself, and colleagues with two clicks of a button.',
            },
            {
                name: 'Document Storage',
                id: 'document_storage',
                available: true,
                description:
                    'Securely store all your documents, and share them with colleagues.',
            },
            {
                name: 'Insert Templates',
                id: 'insert_templates',
                available: true,
                description:
                    'Save templates of clauses, definitions, or anything else quickly, then add them to the workflow with one click.',
            },
            {
                name: 'Multi-Doc Review',
                id: 'multi_doc_review',
                available: false,
                description:
                    'Analyse multiple documents Simultaneously, have your AI assistant review a collection of documents and find conflicting clauses or definitions between them, inconsistencies, mismatching, details, missing, references, and chronological order and timeline.',
            },
            {
                name: 'Multi-Doc Discuss',
                id: 'multi_doc_discuss',
                available: false,
                description:
                    'Analyse multiple documents Simultaneously, discuss document with your AI using chat or talk to it like you were a colleague to play devils advocate, brainstorm ideas or just do a quick sanity check. Ask it to draft emails for you based on the current document or anything else you can imagine.',
            },
            {
                name: 'Multi-Doc Drafting',
                id: 'multi_doc_review',
                available: false,
                description:
                    'Analyse multiple documents Simultaneously, have your AI assistant, draft or edit clauses and definitions, draft full document based on the text selected, make sections shorter or longer, simplify or complexify language, summarise passages, this key, takeaways, explain complex language, or draft anything under your instructions via voice or text.',
            },
            {
                name: 'Team Billing',
                id: 'team_billing',
                available: true,
                description:
                    'Team billing allows you to connect other team member accounts to a single billing account so that you can manage billing and usage in one place.',
            },
            {
                name: 'Dictate Everything',
                id: 'dictate_everything',
                available: false,
                description:
                    'Click to talk on all functions and have it transcribed for you in real time. ',
            },
            {
                name: 'Auto Draft Full Documents',
                id: 'auto_draft_full_documents',
                available: true,
                description:
                    'Either draft a short brief or have your client fill in a questionnaire and the AI will draft a full document outline for you to approve, it will then draft the full document up to 100 pages long. Works on any type of document from pleadings, to wills, binding financial agreements, contracts. We really mean all types of documents.',
            },
            {
                name: 'External Document Sharing',
                id: 'external_document_sharing',
                available: true,
                description:
                    'Instead of downloading a file and sending it on email to a client, simply share a private, secure link to the document with your client and have them edit with track changes, comment or just view it.',
            },
            {
                name: 'Instant Transcribe',
                id: 'instant_transcribe',
                available: true,
                description:
                    'Upload audio or video files and receive accurate transcriptions back in seconds. ',
            },
            {
                name: 'Precedent Manager',
                id: 'precedent_manager',
                available: false,
                description:
                    'Save full documents, special clauses, or any other text to your library and share it with the team so you are all consistent across the firm.',
            },
            {
                name: 'Live Customer Support ',
                id: 'live_customer_support',
                available: true,
                description:
                    'Live customer support online from 8am to 8pm AEST.',
            },
            {
                name: '1 On 1 Training',
                id: '1_on_1_training',
                available: false,
                description:
                    'Live customised training to suit your way of doing things. ',
            },
        ],
    },
]

export default pricingFeatures
