import { ApiService, QueryParams } from './api.service'

export interface TeamMembers {
    id: string
    name: string
    currentPage: number
    pageSize: number
    hasNextPage: boolean
    totalRecordsCount: number
    member: Member[]
    createdAt: string
    updatedAt: string
}

export interface Member {
    email: string
    role: string
    limit: number
    invite_id: string
    isOwner: boolean
    status: boolean
    usage: number
    created_at: number
    updated_at: number
}

export interface IAddMember {
    email: string
    monthlyLimit: number
}

export interface IUpdateMemberLimit {
    email: string
    limit: number
}

export interface InviteMemberRequest {
    invites: {
        email: string
        limit: number
        role: string
    }[]
}
export interface InviteMember {
    invite_id: string
}

export interface removeMember {
    email: string
}

export interface IUpdateMember {
    email: string
    limit: number
    role: string
}

export interface AssignGroupToMemberRequest {
    email: string
    group: string
}

class MembersService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public getMembers = async (query: QueryParams): Promise<TeamMembers> =>
        await this.get(`teams/team-members/`, query)

    public addMember = async (data: InviteMemberRequest) =>
        await this.post(`teams/add-member/`, data)

    public deleteMember = async (email: removeMember) =>
        await this.post(`teams/remove-member/`, email)

    public updateMemberUsageLimit = async (data: IUpdateMemberLimit) =>
        await this.put(`cost_config/update_member_limit`, data)

    public cancelMemberInvitation = async (invite_id: InviteMember) =>
        await this.post(`/teams/cancel-invite/`, invite_id)

    public updateMember = async (data: IUpdateMember) =>
        await this.put(`cost_config/update_member/`, data)

    public assignGroupToMember = async (data: AssignGroupToMemberRequest) => {
        const res = await this.get(`teams/groups/`)
        const groups = res?.data ?? []
        for (const group of groups) {
            const memberIdx = group.members.findIndex(
                (member: any) => member.email === data.email
            )
            if (group.id === data.group && memberIdx === -1) {
                const members = group.members.map((member: any) => member.email)
                members.push(data.email)
                await this.put(`teams/update-group/`, {
                    id: group.id,
                    name: group.name,
                    members: members,
                })
            } else if (group.id !== data.group && memberIdx !== -1) {
                const members = group.members
                    .filter((member: any) => member.email !== data.email)
                    .map((member: any) => member.email)
                await this.put(`teams/update-group/`, {
                    id: group.id,
                    name: group.name,
                    members: members,
                })
            }
        }
    }
}

export default MembersService
