import { AxiosRequestConfig } from 'axios'
import { ApiService } from './api.service'

export interface IAiTranscribeTokenPostInterface {
    document: string
    tokens: Tokens
}
export interface Tokens {
    total_tokens: number
    input_tokens: number
    output_tokens: number
}

class AudioService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public async aiTranscribe(
        data: FormData,
        config: AxiosRequestConfig
    ): Promise<any> {
        return await this.post('/voice2text/transcribe', data, {
            'Content-Type': 'multipart/form-data',
            ...config,
        })
    }
    public aiTranscribeTokenPost = async (
        data: IAiTranscribeTokenPostInterface
    ) => {
        return await this.post('voice2text/transcribe-tokens', data)
    }
}

export default AudioService
