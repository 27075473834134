import { ApiService } from './api.service'

interface AddGroupRequest {
    name: string
    members: string[]
}

interface UpdateGroupRequest {
    id: string
    name: string
    members: string[]
}

class GroupService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public getGroups = async (): Promise<any> => {
        return await this.get(`teams/groups/`)
    }

    public addGroup = async (data: AddGroupRequest): Promise<any> => {
        return await this.post(`teams/create-group/`, data)
    }

    public deleteGroup = async (id: string): Promise<any> => {
        return await this.delete(`teams/delete-group/${id}`)
    }

    public updateGroup = async (data: UpdateGroupRequest): Promise<any> => {
        return await this.put(`teams/update-group/`, data)
    }
}

export default GroupService
