import {
    IPaginationMetaData,
    IFoldersPaginationMetadata,
} from '@/utils/types/common'
import {
    IDocumentQuery,
    IDocumentWriteQuery,
    RequestParamsDraft,
    AIResponseFeedback,
} from '../utils/types/ai'
import { ApiService } from './api.service'
import { AIDraftResponse } from '../utils/types/ai'

interface DocumentAPIResponse extends Document {
    documents: Document[]
    folders: Folder[]
    paginationMetadata: IPaginationMetaData
}

export interface FoldersAPIResponse {
    documents: Document[]
    collaboratorDocuments: Document[]
    folders: Folder[]
    collaboratorFolders: Folder[]
    parentFolderId: string | null
    parentFolderName: string | null
    paginationMetadata: IFoldersPaginationMetadata
}

interface IDocumentCreateResponse {
    documentId: string
}

interface IAddTaskCommentParameters {
    task_id: string
    text: string
}

export interface UserAllDocuments {
    documents: Document[]
}

export interface Breadcrumbs {
    class: string
    command: null
    disabled: false
    label: string
}

export interface SingleDocument {
    Id: string
    editorId: string
    ownerId: string
    title: string
    content: string
    collaborators: any[]
    isPublicShare: boolean
    createdAt: number
    updatedAt: number
    isOwner: boolean
    shareIds: ShareId[]
    breadcrumbs: Breadcrumbs[]
}

export interface ShareId {
    share_id: string
    allow_edit: boolean
    allow_comment: boolean
    link_name: string
}
export interface Document {
    _id: string
    editorId: string
    ownerId: string
    title: string
    content: string
    collaborators: any[]
    folderId: string
    createdAt: number
    updatedAt: number
    isOwner: boolean
    is_public_share: boolean
    share_ids: ShareId[]
}

export interface Folder {
    folderId: string
    folderName: string
    isOwner: boolean
    is_public_share: boolean
    share_ids: ShareId[]
}

export interface IDocument {
    editor_id: string
    title: string
    content: string
    collaborators: []
    folderId?: string
}

export interface SelectedDocument {
    _id: string
    editorId: string
    title: string
    content: string
    isOwner: boolean
}

export interface ChatContextRequest {
    data: string[]
    document_id: string[]
    document_title: string[]
}

export interface UpdateTaskRequest {
    title?: string
    description?: string
    status?: 0 | 1
    priority?: 'none' | 'low' | 'medium' | 'high'
}

export interface IDocumentShareLink {
    link_name: string
    document_id?: string
    folder_id?: string
    allow_edit: boolean
    allow_comment: boolean
    password?: string
}

export interface IDocumentSharePasswordUpdate {
    new_password: string
    id: string
}

export interface ItoggleDocumentShareLink {
    document_id?: string
    folder_id?: string
}

export interface IDocumentShareAccessResponse {
    allowEdit: boolean
    allowComment: boolean
    isPasswordProtected: boolean
    documentId: string
}

export interface IGetSharedListofFolderDocuments {
    documents: Array<string>
}

export interface IDocumentsInSharedFolderResponseResult {
    documents: Document[]
    currentPage: number
    pageSize: number
}

export interface IDocumentsAndFolderInParentFolderResponse {
    documents: Document[]
    collaboratorDocuments: Document[]
    folders: Folder[]
    collaboratorFolders: CollaboratorFolders
    parentFolderId: string | null
    parentFolderName: string | null
    currentPage: number
    pageSize: number
    allowComment: boolean
    allowEdit: boolean
    shareId: string
}

export interface Folder {
    folderId: string
    folderName: string
}

export interface CollaboratorFolders {
    folders: Folder[]
}

export interface IShareBulkDocuments {
    document_ids: Array<string>
    collaborators: Array<string>
}
export interface IMoveBulkDocuments {
    document_ids: Array<string>
    source_folder_id: string | null
    destination_folder_id: string
}

export interface IDocumentWriteQueryResponse {
    response: string
}

export interface ISaveDocumentContent {
    id: string
    content: string
}

export interface ISaveDocumentTitle {
    id: string
    title: string
    content: string
}

class DocumentService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public async removeDocument(id: string): Promise<boolean> {
        if (id.length < 1) return false

        const res = await this.delete('/documents/' + id)
        return res?.message ? true : false
    }
    public async removeDocumentfromfolder(
        folderid: string,
        docid: string
    ): Promise<boolean> {
        if (docid.length < 1) return false
        const res = await this.delete(
            '/documents/folders/' + folderid + '/documents/' + docid
        )
        return res?.message ? true : false
    }
    public async removeFolder(id: string): Promise<boolean> {
        if (id.length < 1) return false

        const res = await this.delete('/documents/folders/' + id)
        return res?.message ? true : false
    }

    public saveDocumentTitle = async (
        data: ISaveDocumentTitle
    ): Promise<any> => {
        if (data.title.length < 1) return false

        await this.put('/documents/' + data.id, {
            title: data.title,
            content: data.content,
        })
    }

    public saveDocumentContent = async (
        data: ISaveDocumentContent
    ): Promise<any> => {
        await this.put('/documents/' + data.id, {
            content: data.content,
        })
    }

    public async aiIngest(data: ChatContextRequest): Promise<any> {
        return await this.post('/ai/chat/ingest', data)
    }

    public async speechToText(data: FormData): Promise<any> {
        try {
            const response = await this.post('/ai/speech_to_text', data)
            if (response) {
                return Promise.resolve({
                    textResult: response.transcript,
                })
            } else {
                return Promise.reject('Error during speech-to-text conversion')
            }
        } catch (error) {
            return Promise.reject(
                'Error during file upload or speech-to-text conversion'
            )
        }
    }

    public async getDefinitions(): Promise<DocumentAPIResponse> {
        return await this.get('/ai/definitions/')
    }

    public async aiQuery(data: IDocumentQuery): Promise<DocumentAPIResponse> {
        return await this.post(`/ai/document/query/${data.queryTerm}`, {
            document_id: data.document_id,
            document_filename: data.document_filename,
            client: data.client,
            consider: data.consider,
        })
    }

    public async aiAction(data: IDocumentQuery): Promise<DocumentAPIResponse> {
        return await this.post(`/ai/action/${data.queryTerm}`, {
            document_id: data.document_id,
            document_filename: data.document_filename,
            client: data.client,
            consider: data.consider,
        })
    }

    public async aiWriteIt(
        data: IDocumentWriteQuery
    ): Promise<IDocumentWriteQueryResponse> {
        return await this.post(`/ai/document/wifm/${data.queryTerm}`, {
            data: data.documentData,
            written: data.written,
        })
    }

    public getAllDocuments = async (
        page: number = 1
    ): Promise<DocumentAPIResponse> => {
        return await this.get(`/documents/?page=${page}`)
    }
    public getDocumentsByFolder = async (
        folderID: string,
        page: number = 1
    ): Promise<FoldersAPIResponse> => {
        return await this.get(
            `/documents/folders/${folderID}/contents?page=${page}`
        )
    }

    public async getDocumentByDocID(docID: string): Promise<SingleDocument> {
        return await this.get(`/documents/${docID}`)
    }

    public getDocumentsByTitle = async (
        title: string,
        folder: any = null
    ): Promise<DocumentAPIResponse> => {
        return await this.post('/documents/search', {
            title: title,
            folder: folder,
        })
    }

    public createDocument = async (
        data: IDocument
    ): Promise<IDocumentCreateResponse> => {
        // try {
        //     if (data.editorId.length < 1)
        //         return { code: 400, message: 'Bad request', data: {} }
        //     return await this.post('/documents/', data)
        // } catch (error) {
        //     const errCode = error?.response?.status
        //     if (errCode === 402) {
        //         navigateTo(`/subscription`)
        //     }
        // }
        return await this.post('/documents/', data)
    }

    public async textToSpeech(data: FormData): Promise<any> {
        try {
            const runtimeConfig = useRuntimeConfig()
            const response = await fetch(
                `${runtimeConfig.public.apiUrl}/ai/streaming_text_to_speech`,
                {
                    method: 'POST',
                    body: data,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        Authorization: `Bearer ${this.authStore.accessToken}`,
                    },
                }
            )
            if (!response?.ok) {
                return Promise.reject('Error: Unexpected response format')
            } else {
                return Promise.resolve(response.blob())
            }
        } catch (error: any) {
            return Promise.reject(`Error: ${error?.message}`)
        }
    }

    public textToSpeechPlayHT = async (
        text: string,
        chat_type: string
    ): Promise<{ url: string }> => {
        return await this.post('ai/playht_text_to_speech', {
            text: text,
            chat_type: chat_type,
        })
    }

    public async createTextTemplate(
        name: string,
        content: string,
        category_id: any
    ): Promise<any> {
        if (name.trim().length == 0 || content.trim().length == 0) return false
        const res = await this.post('/templates/', {
            template_name: name,
            template_content: content,
            category_id: category_id,
        })
        return res
    }

    public async saveTextTemplate(
        id: any,
        name: string,
        content: string,
        category_id: any
    ): Promise<boolean> {
        if (name.trim().length == 0 || content.trim().length == 0) return false
        const res = await this.put('/templates/' + id, {
            template_name: name,
            template_content: content,
            category_id: category_id,
        })
        return res?.message ? true : false
    }

    public async getTextTemplates(): Promise<any> {
        return await this.get('/templates/')
    }

    public async removeTextTemplate(id: any): Promise<boolean> {
        if (id?.length < 1) return false

        const res = await this.delete('/templates/' + id)
        return res?.message ? true : false
    }

    public async getDocumentUsersByID(id: any): Promise<any> {
        return await this.post(`/documents/users`, {
            doc: id,
        })
    }

    public async getUsersByUsernameEmail(q: String): Promise<any> {
        return await this.get(`/users/filter/${q}`)
    }

    public async shareDocument(id: any, data: any): Promise<any> {
        try {
            const response = await this.post(`/documents/share/${id}`, {
                collaborators: data,
            })
            if (response?.message) {
                return true
            } else {
                Promise.reject('Error sharing the document')
            }
        } catch (error) {
            Promise.reject('Error sharing the document')
        }
    }

    public removeCollaborator = async (
        documentId: string
    ): Promise<{ message: string }> => {
        return await this.post(
            `/documents/remove-collaborators/${documentId}`,
            {}
        )
    }

    public async createTemplateCategory(name: string): Promise<boolean> {
        if (name.trim().length == 0) return false
        const res = await this.post('/templates/cat/', {
            name: name,
        })
        return res?.categoryId ? true : false
    }

    public async saveTemplateCategory(id: any, name: string): Promise<boolean> {
        if (name.trim().length == 0) return false
        const res = await this.put('/templates/cat/' + id, {
            name: name,
        })
        return res?.message ? true : false
    }

    public async getTemplateCategories(): Promise<any> {
        return await this.get('/templates/cat/')
    }

    public async removeTemplateCategory(id: any): Promise<boolean> {
        if (id?.length < 1) return false

        const res = await this.delete('/templates/cat/' + id)
        return res?.message ? true : false
    }

    public saveFolderName = async ({
        folderId,
        folderName,
    }: {
        folderId: string
        folderName: string
    }): Promise<boolean> => {
        return await this.put('/documents/folders/' + folderId, {
            name: folderName,
        })
    }

    public async getDocumentUsersByFolderID(id: any): Promise<any> {
        return await this.post(`/documents/folders/users`, {
            folder: id,
        })
    }

    public async shareFolder(id: any, data: any): Promise<any> {
        try {
            const response = await this.post(`/documents/folders/share/${id}`, {
                collaborators: data,
            })
            if (response?.message) {
                return true
            } else {
                Promise.reject('Error sharing the folder')
            }
        } catch (error) {
            Promise.reject('Error sharing the folder')
        }
    }

    public async createTask(
        documentId: string,
        title: string,
        description: string = '',
        members: string[] = [],
        priority: string = 'none',
        groups: string[] = []
    ): Promise<any> {
        if (title.trim().length == 0) return false
        const res = await this.post('/documents/create-task/', {
            document_id: documentId,
            title: title,
            description: description,
            members: members,
            priority: priority,
            groups: groups,
        })
        return res
    }

    public async getTasks(docs: Array<string>, listType: string): Promise<any> {
        const res = await this.post('/documents/tasks/', {
            docs: docs,
            list_type: listType,
        })
        return res ? res : []
    }

    public async deleteTask(id: string): Promise<boolean> {
        const res = await this.delete(`/documents/task/${id}`)
        return res?.success ? true : false
    }

    public aiDraft = async ({
        option,
        data,
    }: {
        option: string
        data: RequestParamsDraft
    }): Promise<AIDraftResponse> => {
        return await this.post(`/ai/draft/${option}`, data)
    }

    public async createFeedback(params: AIResponseFeedback): Promise<boolean> {
        const res = await this.post('/documents/feedback/', params)
        return res?.success ? true : false
    }

    public async getTaskById(id: string): Promise<any> {
        const res = await this.get(`/documents/task/${id}`)
        return res ? res : null
    }

    public async updateTask(
        id: string,
        data: UpdateTaskRequest
    ): Promise<boolean> {
        const res = await this.put(`/documents/task/${id}`, data)
        return res?.success ? true : false
    }

    public deleteDocuments = async (ids: string[]): Promise<boolean> => {
        const res = await this.delete(`documents/deletes/`, {
            ids,
        })
        return res?.success ? true : false
    }

    public documentShareLink = async (
        data: IDocumentShareLink
    ): Promise<{ link: string }> => {
        const response = await this.post(`documents/share-link/`, data)
        return response
    }

    public documentSharePasswordUpdate = async (
        data: IDocumentSharePasswordUpdate
    ) =>
        await this.put(`documents/change-doc-password/${data.id}`, {
            new_password: data.new_password,
        })

    public toggleDocumentShareLink = async (data: ItoggleDocumentShareLink) =>
        await this.post(`documents/toggle-share-link/`, data)

    public getDoumentsAndFoldersInParentsFolder = async ({
        parent_folder_id,
        share_id,
        password,
    }: {
        parent_folder_id: string
        share_id: string
        password?: string
    }): Promise<IDocumentsAndFolderInParentFolderResponse> =>
        await this.get(
            `external_documents/folders/${parent_folder_id}/contents/${share_id}?password=${password}`
        )
    public shareBulkDocuments = async (
        data: IShareBulkDocuments
    ): Promise<boolean> => {
        const res = await this.post(`documents/share-bulk/`, data)
        return res?.success ? true : false
    }

    public moveBulkDocuments = async (data: IMoveBulkDocuments) =>
        await this.post(`documents/move-bulk-documents`, data)

    public async cloneDocument(id: string): Promise<boolean> {
        if (id.length < 1) return false
        const res = await this.post('/documents/duplicate/' + id, {})
        return res?.documentId ? res.documentId : null
    }

    public async cloneFolder(id: string): Promise<boolean> {
        if (id.length < 1) return false
        const res = await this.post('/documents/folders/duplicate/' + id, {})
        return res?.folderId ? res.folderId : null
    }

    public async completeTasks(document_id: string): Promise<boolean> {
        const res = await this.put(`/documents/task/bulk-complete`, {
            document_id: document_id,
        })
        return res?.success ? true : false
    }
    public getAllUserDocuments = async (): Promise<UserAllDocuments> => {
        return await this.get('/documents/all-users-documents')
    }

    public getDocumentUsers = async (documentId: string): Promise<any> => {
        return await this.get(
            `/documents/collaborators-by-document/${documentId}`
        )
    }

    public updateTaskMembers = async (
        taskId: string,
        members: string[]
    ): Promise<any> => {
        return await this.put(`/documents/update-members/${taskId}`, {
            members,
        })
    }

    public addTaskComment = async (data: IAddTaskCommentParameters) => {
        return await this.post(`/documents/add-comment/`, data)
    }

    public deleteTaskComment = async (taskId: string, commentId: string) => {
        return await this.delete(
            `/documents/remove-comment/${taskId}/${commentId}`
        )
    }

    public exportDocumentAsPDF = async (documentIds: Array<string>) => {
        return await this.post(
            `/documents/export-pdf`,
            {
                document_ids: documentIds,
            },
            {
                responseType: 'arraybuffer',
            }
        )
    }
    public exportDocumentAsDocx = async (documentIds: Array<string>) => {
        return await this.post(
            `/documents/export-sfdt`,
            {
                document_ids: documentIds,
            },
            {
                responseType: 'arraybuffer',
            }
        )
    }

    public updateTaskGroups = async (
        taskId: string,
        groups: string[]
    ): Promise<any> => {
        return await this.put(`/documents/assign-task-group/`, {
            id: taskId,
            groups: groups,
        })
    }

    public updateTextTemplateGroups = async (
        templateId: string,
        groups: string[]
    ): Promise<any> => {
        return await this.post(`/templates/assign-group/`, {
            id: templateId,
            groups: groups,
        })
    }

    public saveDocName = async ({
        docId,
        docName,
    }: {
        docId: string
        docName: string
    }): Promise<boolean> => {
        return await this.put('/documents/rename-file/' + docId, {
            title: docName,
        })
    }
}

export default DocumentService
