import { ApiService } from "./api.service";

class SpeechToTextService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig();
        const { apiUrl_version } = runtimeConfig.public;
        super(`${apiUrl_version}`);
    }

    public async speechToText(data: FormData): Promise<any> {
        try {
            const response = await this.post("/speech_to_text", data);
            if (response) {
                return Promise.resolve({
                    textResult: response.transcript,
                });
            } else {
                return Promise.reject("Error during speech-to-text conversion");
            }
        } catch (error) {
            return Promise.reject(
                "Error during file upload or speech-to-text conversion",
            )
        }
    }
}

export default SpeechToTextService;
