import AuthService from '../services/auth.service'
import DocumentService from '../services/document.service'
import { ChatService, ResearchService } from '../services/chat.service'
import UserService from '../services/user.service'
import FolderService from '../services/folder.service'
import AudioService from '../services/audio.service'
import SpeechToTextService from '../services/speechtoText.service'
import SubscriptionService from '@/services/subscription.service'
import PlansService from '@/services/plans.service'
import MembersService from '@/services/members.service'
import InvoicesHistoryService from '@/services/invoices.service'
import UsageService from '@/services/usage.service'
import IntegrationsService from '@/services/integrations.service'
import SharingService from '@/services/sharing.service'
import GroupService from '@/services/group.service'

interface IServiceProvider {
    authService: AuthService
    documentService: DocumentService
    chatService: ChatService
    userService: UserService
    researchService: ResearchService
    audioService: AudioService
    folderService: FolderService
    speechToTextService: SpeechToTextService
    subscriptionService: SubscriptionService
    plansService: PlansService
    membersService: MembersService
    invoicesHistoryService: InvoicesHistoryService
    usageService: UsageService
    integrationsService: IntegrationsService
    sharingService: SharingService
    groupService: GroupService
}

export default defineNuxtPlugin(async (nuxtApp) => {
    const services: IServiceProvider = {
        authService: new AuthService(),
        documentService: new DocumentService(),
        chatService: new ChatService(),
        userService: new UserService(),
        researchService: new ResearchService(),
        audioService: new AudioService(),
        folderService: new FolderService(),
        speechToTextService: new SpeechToTextService(),
        subscriptionService: new SubscriptionService(),
        plansService: new PlansService(),
        membersService: new MembersService(),
        invoicesHistoryService: new InvoicesHistoryService(),
        usageService: new UsageService(),
        integrationsService: new IntegrationsService(),
        sharingService: new SharingService(),
        groupService: new GroupService(),
    }

    return {
        provide: {
            ...services,
        },
    }
})
