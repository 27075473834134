export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"https://i.postimg.cc/NjZFQktP/wing.png"}],"style":[],"script":[{"src":"/_nuxt/assets/scripts/MSClarity.js","defer":true,"type":"text/javascript"},{"src":"https://www.googletagmanager.com/gtag/js?id=G-R807PKMLFJ","defer":true,"async":true},{"src":"/_nuxt/assets/scripts/gtag.js","defer":true,"type":"text/javascript"}],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"Legal Assistant"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null