import { defineStore } from "pinia";
import { User } from "../utils/types/auth";

export const useAuthStore = defineStore("authStore", () => {
  const refreshToken = useLocalStorage("auth/refresh_token", "");
  const isLogged = useLocalStorage("auth/isLogged", false);

  const accessToken = useLocalStorage("auth/access_token", "");
  const userData: Ref<User> = ref({} as User);
  const tempEmail = ref("");

  function setTokens(accToken = "", refToken = ""): void {
    accessToken.value = accToken;
    refreshToken.value = refToken;
  }

  return {
    setTokens,
    accessToken,
    refreshToken,
    userData,
    isLogged,
    tempEmail,
  };
});

type UseNullStore = ReturnType<typeof defineStore>;
type NullStore = ReturnType<UseNullStore>;
export type AuthStoreType = ReturnType<typeof useAuthStore>;
export type AuthStore = Omit<AuthStoreType, keyof NullStore>;
