import { abilitiesPlugin } from '@casl/vue'
import defineAbilityFor from '@/utils/ability'
import AuthService from '@/services/auth.service'
import SubscriptionService from '@/services/subscription.service'
import { AuthStore } from '@/store/auth.store'
import { NotificationStore } from '@/store/notification.store'
import { useQueryClient } from '@tanstack/vue-query'
import pricingFeatures from '@/utils/helpers/PricingFeatures'

export default defineNuxtPlugin(async (nuxt) => {
    const queryClient = useQueryClient()

    const {
        $authService,
        $subscriptionService,
        $authStore,
        $notificationStore,
    } = useNuxtApp()
    const authStore = $authStore as AuthStore
    const authSerive = $authService as AuthService
    const subscriptionService = $subscriptionService as SubscriptionService

    const notificationStore = $notificationStore as NotificationStore

    let userWithSubscription = null
    let highestPaidPlan = null
    if (authStore.isLogged) {
        try {
            const user = await authSerive.getUser()
            authStore.userData = user

            const activeSubscription =
                await subscriptionService.getSubscription()

            const plans = pricingFeatures

            queryClient.setQueryData(['user'], user)

            queryClient.setQueryData(['subscription'], activeSubscription)
            queryClient.setQueryData(['plans'], plans)
            const sortedPlans = Object.values(plans).sort(
                (a, b) => b.monthly_cost - a.monthly_cost
            )
            if (sortedPlans.length > 0) {
                highestPaidPlan = sortedPlans[0]
            }

            userWithSubscription = {
                ...user,
                subscription: activeSubscription,
            }
        } catch (error) {
            notificationStore.notifyError({
                summary: 'Error',
                detail: 'Access token expired, please login.',
                life: 3000,
            })
        }
    }

    nuxt.vueApp.use(
        abilitiesPlugin,
        defineAbilityFor(userWithSubscription, highestPaidPlan?.features ?? [])
    )
})
