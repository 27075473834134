export default defineNuxtRouteMiddleware(async () => {
    const config = useRuntimeConfig()
    const version = useLocalStorage('version', '')

    if (!version.value || version.value !== config.public.version) {
        localStorage.clear()
        localStorage.setItem('version', config.public.version)
        window.location.reload()
    }

    return
})
