import { AuthStore } from '@/store/auth.store'
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxt) => {
    const router = useRouter()
    const config = useRuntimeConfig()
    const { $authStore } = useNuxtApp()
    const authStore = $authStore as AuthStore

    Sentry.init({
        app: nuxt.vueApp,
        dsn: config.public.sentryDSN,
        enabled: config.public.appEnv === 'production',
        integrations: [Sentry.browserTracingIntegration({ router })],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [
            'localhost',
            config.public.apiUrl,
            config.public.syncfusionServerUrl,
        ],

        denyUrls: ['https://embed.tawk.to'],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1, // Change in prod
        replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    })

    if (authStore.isLogged) {
        Sentry.setUser({
            id: authStore.userData.id,
            email: authStore.userData.email,
        })
    }
})

import('@sentry/vue').then((lazyLoadedSentry) => {
    Sentry.addIntegration(
        lazyLoadedSentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        })
    )
})
