import { User, Subscription } from '../utils/types/auth'
import { ApiService } from './api.service'

export interface IReferUserRequest {
    email: string
}

class UserService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }
    public async getUserById(userId: string): Promise<User> {
        return this.get(
            `users/${userId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async updateUser(
        userId: string,
        data: Partial<User>
    ): Promise<User> {
        return this.put(
            `users/${userId}`,
            {
                ...data,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async updateProfilePicture(data: FormData): Promise<boolean> {
        const response = await this.post('users/picture', data)
        return response?.url ? true : false
    }

    public async removeProfilePicture(): Promise<boolean> {
        const response = await this.delete('users/picture')
        return response?.message ? true : false
    }

    public async updateProfileSettings(data: Partial<User>): Promise<User> {
        return this.put(
            'users/profile_settings',
            {
                ...data,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async updateContactPhoneNumber(data: Partial<User>): Promise<User> {
        return this.put(
            'users/contact_phone_number',
            {
                ...data,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async changePassword(data: {
        old_password: string
        new_password: string
    }): Promise<User> {
        return this.put(
            'users/change_password',
            {
                ...data,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async generateOtp(email: string): Promise<any> {
        return this.post('auth/generate-otp', {
            user_id: email,
        })
    }

    public async verifyOtp(email: string, otp: string): Promise<any> {
        return this.post('auth/verify-otp', {
            user_id: email,
            otp: otp,
        })
    }

    public async toggle2fa(email: string): Promise<any> {
        return this.post('auth/toggle-2fa', {
            user_id: email,
        })
    }

    public async getSubscriptionById(
        subscriptionId: string
    ): Promise<Subscription> {
        return this.get(
            `subscriptions/${subscriptionId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async createOrUpdateSubscription(
        subscriptionId: string,
        data: Partial<Subscription>
    ): Promise<Subscription> {
        return this.put(
            `subscriptions/${subscriptionId}`,
            {
                ...data,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async getUserByStripeCustomerId(
        stripeCustomerId: string
    ): Promise<User> {
        return this.get(
            `users/stripe/${stripeCustomerId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async mySubscriptionStatus(): Promise<any> {
        return this.get(
            'users/my_suscription_status',
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async cancelMySubscription(): Promise<any> {
        return this.get(
            'subscriptions/cancel/',
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async getPlans(): Promise<any> {
        return this.get(
            'subscriptions/plan/',
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public referUser = async (data: IReferUserRequest) =>
        await this.post('users/refer_user/', data)
}

export default UserService
