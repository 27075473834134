import { ApiService } from './api.service'
export interface IInvoiceHistory {
    total_count: number
    data: IInvoiceHistoryData[]
}
export interface IInvoiceHistoryData {
    id: string
    number: number
    description: string
    created: string
    amount_paid: number
    status: string
    invoice_pdf: string
}

class InvoicesHistoryService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }
    public getInvoicesHistory = async (
        params: string
    ): Promise<IInvoiceHistory> => {
        const url = `subscriptions/fetch-invoices/?${params}`
        return this.get(url)
    }
}

export default InvoicesHistoryService
