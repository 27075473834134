import { ApiService } from './api.service'
import { ShareId } from './document.service'

export interface IGetFolderResponse {
    Id: string
    ownerId: string
    folderName: string
    createdAt: number
    updatedAt: number
    parentFolderId: string | null
    isPublicShare: boolean
    isOwner: boolean
    shareIds: ShareId[]
}

class FolderService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public async createFolder(data: {
        folder_name: string
        parent_folder_id: string | null
    }): Promise<any> {
        return await this.post('/documents/folders', data)
    }

    public moveDocument = async (data: any): Promise<any> => {
        return await this.post('/documents/move-document', data)
    }

    public moveFolder = async (data: any): Promise<any> => {
        return await this.post('/documents/move-folder', data)
    }

    public getFoldersTree = async (): Promise<{
        folders: {
            key: string
            label: string
            parent: string | null
        }[]
    }> => {
        return await this.get('/documents/folders-tree')
    }

    public getFolder = async (folder_id: string): Promise<IGetFolderResponse> =>
        await this.get(`/documents/get-folder/${folder_id}`)
}

export default FolderService
