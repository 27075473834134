import { ApiService } from './api.service'
import { Document, Folder } from './document.service'

export interface IGetPermissionsResponse {
    allowComment: boolean
    allowEdit: boolean
    isPasswordProtected: boolean
    linkName: string
}

export interface IGetExternalDocumentResponse {
    Id: string
    editorId: string
    ownerId: string
    title: string
    content: string
    collaborators: any[]
    isPublicShare: boolean
    createdAt: number
    updatedAt: number
    folderId: string | null
}

export interface IUpdateExternalDocumentRequest {
    content: string
}

export interface INestedFoldersAndDocuments {
    documents: Document[]
    collaboratorDocuments: any[]
    folders: Folder[]
    collaboratorFolders: any[]
    parentFolderId: string | null
    parentFolderName: string | null
    currentPage: number
    pageSize: number
    allowComment: boolean
    allowEdit: boolean
}

class SharingService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public getPermissions = async (data: {
        share_id: string
    }): Promise<IGetPermissionsResponse> => {
        const response = await this.get(
            `documents/get-permission/${data.share_id}`
        )
        return response
    }

    public getExternalDocument = async ({
        id,
        share_id,
        password,
    }: {
        id: string
        share_id: string
        password?: string
    }): Promise<IGetExternalDocumentResponse> =>
        await this.get(
            `external_documents/${id}/${share_id}?password=${password}`
        )

    public updateExternalSharedDocument = async ({
        id,
        share_id,
        data,
        password,
    }: {
        id: string
        share_id: string
        password: string
        data: IUpdateExternalDocumentRequest
    }): Promise<number> =>
        await this.put(
            `external_documents/${id}/${share_id}?password=${password}`,
            data
        )

    public getNestedFoldersAndDocuments = async ({
        folder_id,
        share_id,
        password,
    }: {
        folder_id: string
        share_id: string
        password?: string
    }): Promise<INestedFoldersAndDocuments> =>
        await this.get(
            `external_documents/folders/${folder_id}/contents/${share_id}?password=${password}`
        )

    public updatePermissions = async (data: {
        body: {
            allow_edit: boolean
            allow_comment: boolean
            link_name: string
        }
        share_id: string
    }) =>
        await this.put(
            `documents/change-permissions/${data.share_id}`,
            data.body
        )

    public updatePassword = async (data: {
        id: string
        new_password: string
    }) => await this.put(`documents/change-doc-password/${data.id}`, data)

    public deleteLink = async (share_id: string) =>
        await this.delete(`documents/delete-share/${share_id}`)
}

export default SharingService
