import { ApiService } from './api.service'

export interface ActionStepURLResponse {
    url: string
}

export interface ActionStepDocumentsResponse {
    documents: {
        id: number
        name: string
        createdTimestamp: string
        fileSize?: number
        extension: string
        fileName?: string
        sharepointUrl: string
        action_id: number
        content_error?: string
        content?: string
    }[]
}

export interface Integrations {
    actionstep_email: any
    ownerId: string
    name: string
    status: string
}

export interface ActionStepUploadDocumentRequest {
    files: File
}

export interface CreateActionStepFolderRequest {
    folder_structure: {
        name: string
        files?: string[]
    }
    parent_folder_id?: number
}

export interface Folder {
    id: number
    name: string
    action_id: number
    parent_folder_id: number | null
}

export interface ListActionStepFoldersResponse {
    folders: Folder[]
}

export interface Links {
    action: string
    checkedOutBy: string | null
    folder: string | null
    createdBy: string
    tags: string[]
    documentTemplate: string | null
}

export interface ActionStepDocument {
    id: number
    name: string
    createdTimestamp: string
    fileSize: number | null
    extension: string
    fileName: string | null
    sharepointUrl: string
    action_id: number
}

export interface ActionStepRootDocumentsResponse {
    documents: ActionStepDocument[]
}

class IntegrationsService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public disconnectIntegration = async (site: string): Promise<any> =>
        await this.delete(`users/integrations/${site}`)

    public actionStepUrl = async (): Promise<ActionStepURLResponse> =>
        await this.get(`actionstep/auth/login`)

    public importFromActionStep = async (email: string): Promise<any> => {
        return await this.get(`actionstep/document`, {
            user_email: email,
        })
    }

    public getIntegrations = async (): Promise<Record<number, Integrations>> =>
        await this.get(`integration`)

    public getActionStepDocuments =
        async (): Promise<ActionStepDocumentsResponse> =>
            await this.get('actionstep/document')

    public uploadDocumentFromActionStep = async (
        data: ActionStepUploadDocumentRequest
    ) => {
        const formData = new FormData()
        formData.append('files', data.files)

        return await this.post('actionstep/upload/document/', formData)
    }

    public createActionStepFolder = async (data: FormData) =>
        await this.post('actionstep/create-folder', data)

    public getActionStepFolders = async (
        folder_id: string
    ): Promise<ListActionStepFoldersResponse> =>
        await this.get(
            `actionstep/folder-content?${
                folder_id ? `folder_id=${folder_id}` : ''
            }`
        )

    public listActionStepFolderDocuments = async (
        folder_id: number | null
    ): Promise<ActionStepRootDocumentsResponse> =>
        await this.get(`actionstep/get-folder-documents?folder_id=${folder_id}`)

    public getActionStepRootDocuments = async () =>
        await this.get('actionstep/root-document')
}

export default IntegrationsService
