import { ResearchChatItem } from '@/utils/types/ai'
import { ApiService } from './api.service'

export interface ChatQueryRequest {
    data: string
    document_id: string[]
    document_title: string[]
    current_document_id: string
}

export interface ChatContextRequest {
    data: string[]
    document_id: string[]
    document_title: string[]
}

export interface MakeChatQuery {
    query: string
    document_id: string[]
}

export interface IChatHistoryResponse {
    prevChat: {
        question: string
        response: string
        related_questions?: string[]
    }[]
}

export interface ResearchChatThread extends ReSearchChatsList {
    messages?: {
        question: string
        response: string
    }[]
}

export interface ResearchChatMessage {
    question: string
    response: string
    relatedQuestions?: string[]
}

export interface ReSearchChatsList {
    id: string
    assistant_id: string
    name: string
    is_named: boolean
}

export interface RenameResearchChatRequest {
    thread_id: string
    new_name: string
}

export interface ResearchChatRequest {
    thread_id: string
    message: string
    documents: string[]
}

export interface ResearchChatMessageResponse {
    response: string
    relatedQuestions: string[]
}

export interface DiscussChatMessageResponse {
    response: string
    relatedQuestions: string[]
}

class ChatService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public async aiQuery(
        data: ChatQueryRequest
    ): Promise<DiscussChatMessageResponse> {
        return await this.post('/ai/chat/query', data)
    }

    public async discussChatHistory(data: {
        document_id: string[]
        current_document_id: string
    }): Promise<IChatHistoryResponse> {
        return await this.post(`/ai/chat/get_chat`, data)
    }

    public async getRequests(type: string): Promise<any> {
        return await this.get(`/ai/chat/requests/${type}`)
    }

    public async createRequest(
        type: string,
        name: string,
        content: string
    ): Promise<string | null> {
        if (name.trim().length == 0 || content.trim().length == 0) return null
        const res = await this.post('/ai/chat/request', {
            type: type,
            name: name,
            content: content,
        })
        return res?.message ? res.message : null
    }

    public async editRequest(
        id: string,
        type: string,
        name: string,
        content: string
    ): Promise<string | null> {
        if (id.length < 1) return null
        if (name.trim().length == 0 || content.trim().length == 0) return null
        const res = await this.put(`/ai/chat/request/${id}`, {
            type: type,
            name: name,
            content: content,
        })
        return res?.message ? res.message : null
    }

    public async deleteRequest(id: string): Promise<string | null> {
        if (id.length < 1) return null
        const res = await this.delete(`/ai/chat/request/${id}`)
        return res?.message ? res.message : null
    }
}

class ResearchService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public async aiResearch(
        data: MakeChatQuery
    ): Promise<ResearchChatMessageResponse> {
        return await this.post('/research/query', data)
    }

    public async getResearchChat(): Promise<IChatHistoryResponse> {
        return await this.get('/research/get_chat')
    }

    public async aiIngest(data: ChatContextRequest): Promise<any> {
        return await this.post('/research/ingest', data)
    }

    public createResearchChat =
        async (): Promise<ResearchChatThread | null> => {
            const response = await this.post('/research/create_chat', {
                thread_name: 'New Chat',
            })
            if (response) {
                return {
                    id: response.threadId,
                    assistant_id: response.assistantId,
                    name: response.threadName,
                    is_named: response.isNamed,
                }
            } else {
                return null
            }
        }

    public getResearchChatsList =
        async (): Promise<Array<ResearchChatItem> | null> => {
            const response = await this.get('/research/list_chats')
            if (response) {
                return Object.values(response).map((item: any) => {
                    return {
                        id: item.thread_id,
                        assistant_id: item.assistant_id,
                        name: item.thread_name,
                        is_named: item.is_named,
                    }
                })
            } else {
                return null
            }
        }

    public renameResearchChat = async (data: RenameResearchChatRequest) =>
        await this.put(`/research/rename_chat`, data)

    public getResearchChatData = async (
        thread_id: string
    ): Promise<Array<ResearchChatMessage>> => {
        const response = await this.get(`/research/chat/${thread_id}`)
        if (response) {
            return Object.values(response).map((item: any) => {
                return {
                    question: item.question,
                    response: item.response,
                    relatedQuestions: item.related_questions,
                }
            })
        } else {
            return []
        }
    }

    public async sendMessageResearchChat(
        data: ResearchChatRequest
    ): Promise<ResearchChatMessageResponse | null> {
        const response = await this.post('/research/chat', data)
        return response
    }

    public deleteResearchChat = async (thread_id: string): Promise<boolean> => {
        const response = await this.delete('/research/delete_chat', {
            thread_id: thread_id,
        })
        if (response.message) {
            return true
        }
        return false
    }
}

export { ChatService, ResearchService }
