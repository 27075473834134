import { defineStore } from 'pinia'
import { useToast } from 'primevue/usetoast'

enum ToastSeverityEnum {
    SUCCESS = 'success',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
}
export type Toast = {
    severity: ToastSeverityEnum
    summary: string
    detail?: string
    life?: number
}
export const useNotificationStore = defineStore('notificationStore', () => {
    const toast = useToast()

    const notify = async (
        severity: ToastSeverityEnum,
        {
            summary,
            detail = '',
            life = 3000,
        }: { summary: string; detail?: string; life?: number }
    ) => {
        return toast.add({
            severity,
            summary,
            detail,
            life,
        })
    }

    const notifyError = ({
        summary = 'Error',
        detail,
        life,
    }: {
        summary?: string
        detail?: string
        life?: number
    }) => {
        return notify(ToastSeverityEnum.ERROR, { summary, detail, life })
    }

    const notifySuccess = ({
        summary = 'Success',
        detail,
        life,
    }: {
        summary?: string
        detail?: string
        life?: number
    }) => {
        return notify(ToastSeverityEnum.SUCCESS, { summary, detail, life })
    }

    const notifyWarning = ({
        summary = 'Warning',
        detail,
        life,
    }: {
        summary?: string
        detail?: string
        life?: number
    }) => {
        return notify(ToastSeverityEnum.WARN, { summary, detail, life })
    }

    const notifySubscriptionWarning = () => {
        return notifyWarning({
            summary: 'Warning',
            detail: 'Please upgrade to access this feature.',
        })
    }

    return {
        notifyError,
        notifySuccess,
        notifyWarning,
        notifySubscriptionWarning,
    }
})

type UseNullStore = ReturnType<typeof defineStore>
type NullStore = ReturnType<UseNullStore>
export type NotificationStoreType = ReturnType<typeof useNotificationStore>
export type NotificationStore = Omit<NotificationStoreType, keyof NullStore>
