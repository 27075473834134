<script lang="ts" setup>
import { useConfirm } from 'primevue/useconfirm'

const deleteDialogInput = ref('')

const confirm = useConfirm()

const toLowerCase = (text: string) => {
    return text ? text.toLowerCase() : ''
}

const onEnter = (accept: (() => void) | undefined) => {
    if (
        (toLowerCase(deleteDialogInput.value) !== 'delete' &&
            toLowerCase(deleteDialogInput.value) !== 'remove') ||
        !accept
    )
        return

    accept()
    confirm.close()
}
</script>

<template>
    <ConfirmDialog
        id="deleteDialog"
        group="deleteDialog"
        :draggable="false"
        @show="deleteDialogInput = ''"
        :pt="{
            acceptButton: {
                root: {
                    disabled:
                        toLowerCase(deleteDialogInput) !== 'delete' &&
                        toLowerCase(deleteDialogInput) !== 'remove',
                },
            },
        }"
    >
        <template #message="{ message }">
            <div class="flex flex-column p-4">
                <div class="flex">
                    <i :class="message.icon" style="font-size: 1.5rem"></i>
                    <p class="pl-2">{{ message.message }}</p>
                </div>
                <InputText
                    class="mt-4"
                    v-model="deleteDialogInput"
                    type="text"
                    size="small"
                    placeholder=""
                    :autofocus="true"
                    @keypress.enter="onEnter(message.accept)"
                />
            </div>
        </template>
    </ConfirmDialog>
</template>