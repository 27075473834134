import { Pinia } from "pinia";
import { AuthStore, useAuthStore } from "../store/auth.store";
import { UndefOrNullable } from "../utils/types/common";
import {
  NotificationStore,
  useNotificationStore,
} from "../store/notification.store";

type PiniaInstance = UndefOrNullable<Pinia>;
export default defineNuxtPlugin(({ $pinia }) => {
  const stores: { authStore: AuthStore; notificationStore: NotificationStore } =
    {
      authStore: useAuthStore($pinia as PiniaInstance),
      notificationStore: useNotificationStore($pinia as PiniaInstance),
    };

  return {
    provide: {
      ...stores,
    },
  };
});
