<template>
    <div class="px-5 min-h-screen flex justify-content-center align-items-center">
        <div class="z-1 text-center">
            <div class="text-900 font-bold text-8xl mb-4">404</div>
            <p class="line-height-3 mt-0 mb-5 text-700 text-xl font-medium">
                Page not found
            </p>
            <NuxtLink to="/">
                <button type="button" class="p-button p-button-primary cursor-pointer font-medium p-button-raised">
                    Go back home
                </button>
            </NuxtLink>
        </div>
    </div>
</template>
