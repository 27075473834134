<script setup>
import { onMounted } from 'vue'
import { registerLicense } from '@syncfusion/ej2-base'
import DeleteConfirmation from '@/components/DeleteConfirmation.vue'

const config = useRuntimeConfig()
const route = useRoute()

registerLicense(config.public.syncfusionApiKey)

onMounted(() => {
    let s1 = document.createElement('script')
    s1.async = true
    s1.src = 'https://embed.tawk.to/65af5a360ff6374032c394ef/1hkqfgp98'
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    if (route.name !== 'template-id') {
        document.body.appendChild(s1)
    }
})
</script>
<template>
    <Toast
        position="bottom-right"
        error-icon="none"
        success-icon="none"
        warn-icon="none"
    />
    <DeleteConfirmation />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>
