import { ApiService } from './api.service'

export interface Feature {
    name: string
    id: string
    description: string
    available: boolean
}

export interface IPlan {
    id: number
    name: string
    monthly_price_id: string
    yearly_price_id: string
    original_yearly: number
    monthly_cost: number
    yearly_cost: number
    features: Feature[]
}

class PlansService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public getPlans = async (): Promise<Record<number, IPlan>> => {
        return await this.get('/subscriptions/plans_list/')
    }
}

export default PlansService
