import revive_payload_client_Frd8gASYWa from "/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_CrWB4n4PyO from "/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_aQoh9E5uaZ from "/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_LPAD84wWwn from "/.nuxt/components.plugin.mjs";
import unhead_3Bi0E2Ktsf from "/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5ZRYOjuei0 from "/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_kCUITUWXUN from "/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_prime_vue_qlMAzTbiFO from "/app/plugins/01.prime-vue.ts";
import _02_store_provider_z9PZ6kAAOR from "/app/plugins/02.store-provider.ts";
import _03_service_provider_D28mE4tZDA from "/app/plugins/03.service-provider.ts";
import _04_vue_query_BEwQaTHKDB from "/app/plugins/04-vue-query.ts";
import _05_casl_abXtJlnNMj from "/app/plugins/05-casl.ts";
import sentry_BsencThQ91 from "/app/plugins/sentry.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  router_CrWB4n4PyO,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_LPAD84wWwn,
  unhead_3Bi0E2Ktsf,
  prefetch_client_5ZRYOjuei0,
  chunk_reload_client_kCUITUWXUN,
  _01_prime_vue_qlMAzTbiFO,
  _02_store_provider_z9PZ6kAAOR,
  _03_service_provider_D28mE4tZDA,
  _04_vue_query_BEwQaTHKDB,
  _05_casl_abXtJlnNMj,
  sentry_BsencThQ91
]