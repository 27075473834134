import {
    User,
    Tokens,
    IUserSignUp,
    IUserSignIn,
    IRestorePasswordOutput,
    IResetPasswordInput,
} from '@/utils/types/auth'
import { ApiService } from './api.service'
import { ParsedQuery } from 'query-string'

export interface ICheckUserExists {
    email: string
    username: string
    phone_no: string
}

interface VerificationEmailRequest {
    email: string
    first_name: string
}
class AuthService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public login = async ({
        data,
        queryParams,
    }: {
        data: IUserSignIn
        queryParams?: ParsedQuery<string>
    }): Promise<Tokens> => {
        return await this.post(`auth/sign-in`, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: queryParams,
        })
    }

    public async register(
        registerData: IUserSignUp,
        queryParams?: ParsedQuery<string>
    ) {
        const res = await this.post('auth/sign-up', registerData, {
            params: queryParams,
        })

        if (res.accessToken) {
            this.authStore.setTokens(res.accessToken, res.refreshToken)
            this.authStore.userData = await this.getUser()
            this.authStore.isLogged = true
        }
        return { res: res, user: this.authStore.userData }
    }

    public async getUser(): Promise<User> {
        return this.get(
            'auth/user',
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.authStore.accessToken}`,
                },
            }
        )
    }

    public async checkIfExistsUser(
        email: string,
        username: string,
        phone_no: string
    ): Promise<ICheckUserExists> {
        return this.post('auth/check-user', {
            email: email,
            username: username,
            phone_no: phone_no,
        })
    }

    public async sendResetPasswordEmail(
        email: string
    ): Promise<{ detail: string }> {
        return this.post('auth/forgot-password', { email })
    }

    public async resetPassword(
        data: IResetPasswordInput
    ): Promise<IRestorePasswordOutput> {
        return await this.put('auth/reset-password', data)
    }

    public async sendVerificationCodeEmail(
        data: VerificationEmailRequest
    ): Promise<{ detail: string }> {
        return this.post('auth/send-verification-code', data)
    }
}

export default AuthService
