import { NotificationStore } from './../store/notification.store'
import axios, { Axios, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
    objKeysToCamelCase,
    objKeysToSnakeCase,
} from '../utils/helpers/switchCase'
import { Tokens } from '../utils/types/auth'
import { AuthStore } from '../store/auth.store'
import { useLowCredits } from '@/composables/useLowCredits'
import { useMaxSpendLimitDialog } from '@/composables/useMaxSpendLimitDialog'

export type QueryParams = Record<string, string>
// const { toogle } = useLowCredits()
export class ApiService {
    public authStore: AuthStore
    public notificationStore: NotificationStore
    public axiosInstance: Axios
    public axiosControllers: Array<AbortController>
    public toggleLowCreditsDialog: (val?: boolean) => void
    public toggleMaxSpendLimitDialog: (val?: boolean) => void

    constructor(baseUrl: string) {
        this.axiosInstance = axios.create({ baseURL: baseUrl })
        const { $authStore, $notificationStore } = useNuxtApp()
        this.authStore = $authStore as AuthStore
        this.notificationStore = $notificationStore as NotificationStore
        this.axiosControllers = []
        this.toggleLowCreditsDialog = useLowCredits().toggle
        this.toggleMaxSpendLimitDialog = useMaxSpendLimitDialog().toggle

        // Interceptors
        this.handleErrors()
        this.addAuthHeader()
        this.handleRefresh()
    }

    private newAbortSignal() {
        const newAbortC = new AbortController()
        this.axiosControllers.push(newAbortC)
        return newAbortC.signal
    }

    public async get(
        uri: string,
        params?: QueryParams,
        config?: Record<string, any>
    ): Promise<any> {
        const urisWithAbort: string[] = []
        if (urisWithAbort.some((x) => uri.includes(x))) {
            let newSignal = this.newAbortSignal()
            if (config) {
                config.signal = newSignal
            } else {
                config = { signal: newSignal }
            }
        }
        const response = await this.axiosInstance.get(uri, {
            params,
            ...config,
        })
        return this.handleResponse(response)
    }

    public async post(
        uri: string,
        data: Record<string, any> | FormData,
        config?: Record<string, any>
    ): Promise<any> {
        // let postData: Record<string, any> | FormData

        // if (data instanceof FormData) {
        //   postData = data;
        // } else {
        //   const preparedData = objKeysToSnakeCase(data);
        //   postData = preparedData;
        // }
        const urisWithAbort: string[] = [
            '/ai/document/query/',
            '/ai/action/',
            '/ai/draft/',
            '/ai/chat/query',
            '/research/query',
        ]
        const FormConfig: AxiosRequestConfig = {
            Authorization: `Bearer ${this.authStore.accessToken}`,
            'Content-Type': 'multipart/form-data',
            ...config,
        }
        if (urisWithAbort.some((x) => uri.includes(x))) {
            let newSignal = this.newAbortSignal()
            if (config) {
                config.signal = newSignal
            } else {
                config = { signal: newSignal }
            }
            FormConfig.signal = newSignal
        }
        const postData: Record<string, any> | FormData = data
        if (data instanceof FormData) {
            const response = await this.axiosInstance.post(
                uri,
                data,
                FormConfig
            )
            return this.handleResponse(response)
        }

        const response = await this.axiosInstance.post(uri, postData, {
            ...this.baseConfig,
            ...config,
        })

        if (response.headers['content-type'] === 'application/octet-stream') {
            return response
        }

        return this.handleResponse(response)
    }

    public async patch(
        uri: string,
        data: Record<string, any>,
        config?: Record<string, any>
    ): Promise<any> {
        const response = await this.axiosInstance.patch(
            uri,
            { ...data },
            { ...this.baseConfig, ...config }
        )
        return this.handleResponse(response)
    }

    public async put(
        uri: string,
        data: Record<string, any>,
        config?: Record<string, any>
    ): Promise<any> {
        const response = await this.axiosInstance.put(
            uri,
            { ...data },
            { ...this.baseConfig, ...config }
        )
        return this.handleResponse(response)
    }

    public async delete(
        uri: string,
        data?: Record<string, any>,
        config?: Record<string, any>
    ): Promise<any> {
        const response = await this.axiosInstance.delete(uri, {
            data,
            ...config,
        })
        return this.handleResponse(response)
    }

    private baseConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
    }

    private handleResponse(response: AxiosResponse) {
        return objKeysToCamelCase(response.data)
    }

    private handleErrors() {
        this.axiosInstance.interceptors.response.use(
            (response) => {
                if (!response.data || !response.data.details) return response
                this.notificationStore.notifySuccess({
                    summary: response.statusText,
                    detail: response.data.detail,
                })

                return response
            },
            (err) => {
                if (!err) return
                if (err?.code === 'ERR_CANCELED') return
                if (
                    [402].includes(err?.response?.status || 0) &&
                    err?.response?.data?.detail.includes('Not enough balance')
                ) {
                    this.toggleLowCreditsDialog(true)
                    return
                }
                if (
                    [422].includes(err?.response?.status) &&
                    err?.response?.data?.detail?.monthly_limit_exceeded
                ) {
                    this.toggleMaxSpendLimitDialog(true)
                    return
                }
                if (
                    [401].includes(err?.response?.status || 0) &&
                    err?.response?.data?.detail.includes(
                        'Password is incorrect'
                    )
                ) {
                    this.notificationStore.notifyError({
                        summary: 'Password is Incorrect',
                    })
                    return
                }
                if (
                    !(
                        [401, 422, 403].includes(err?.response?.status || 0) &&
                        (err?.response?.data?.detail.includes('access token') ||
                            err?.response?.data?.detail.includes(
                                'token is wrong or was expired'
                            ))
                    )
                ) {
                    this.notificationStore.notifyError({
                        summary: err.response && err.response.statusText,
                        detail: err.response && err.response.data.detail,
                    })
                }
                return Promise.reject(err)
            }
        )
    }

    public async refresh(): Promise<Tokens> {
        try {
            const res = await this.post('auth/refresh', {})
            const { accessToken, refreshToken } = res
            this.authStore.setTokens(accessToken, refreshToken)
            this.authStore.userData = await this.get(
                'auth/user',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            return res
        } catch (error) {
            this.authStore.isLogged = false
            return navigateTo('/auth/login')
        }
    }

    private async addAuthHeader() {
        this.axiosInstance.interceptors.request.use((requestConfig) => {
            if (requestConfig.url?.includes('refresh')) {
                if (!this.authStore.refreshToken) {
                    this.authStore.isLogged = false
                    return navigateTo('/auth/login')
                }
                requestConfig.headers.Authorization = `Bearer ${this.authStore.refreshToken}`
            }
            if (
                requestConfig.url?.includes('auth') &&
                !requestConfig.url?.includes('actionstep')
            )
                return requestConfig

            requestConfig.headers.Authorization = `Bearer ${this.authStore.accessToken}`
            return requestConfig
        })
    }

    private async handleRefresh() {
        this.axiosInstance.interceptors.response.use(
            (response) => response,
            async (err) => {
                const originalRequest = err.config
                if (
                    originalRequest.url.includes(`auth/refresh`) ||
                    (err.response?.status == 499 &&
                        !originalRequest.url.includes(`auth`))
                ) {
                    this.authStore.isLogged = false
                    return navigateTo('/auth/login')
                } else if (
                    [401, 422, 403].includes(err.response?.status) &&
                    !originalRequest.url.includes(`auth`)
                ) {
                    const res = await this.refresh()
                    if (res.accessToken) {
                        return this.axiosInstance.request(originalRequest)
                    }
                }
                return Promise.reject(err)
            }
        )
    }
}
