import { default as accesscHBIYVS2zrMeta } from "/app/pages/auth/access.vue?macro=true";
import { default as email_45sentIDwxj6WtPGMeta } from "/app/pages/auth/email-sent.vue?macro=true";
import { default as errorT0PrdEAY6vMeta } from "/app/pages/auth/error.vue?macro=true";
import { default as forgot_45passwordPFs5wuK5FuMeta } from "/app/pages/auth/forgot-password.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as new_45passwordBuzOWGoMHoMeta } from "/app/pages/auth/new-password.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as indexD1OagYyPfPMeta } from "/app/pages/billing/index.vue?macro=true";
import { default as calendarsHG7ZbpD8zMeta } from "/app/pages/calendar.vue?macro=true";
import { default as chatqOxyUSQXKlMeta } from "/app/pages/chat.vue?macro=true";
import { default as _91id_93mY4jWfYDC4Meta } from "/app/pages/d/[id].vue?macro=true";
import { default as indexnOUxntqq7pMeta } from "/app/pages/d/index.vue?macro=true";
import { default as filespcJAB5HGY4Meta } from "/app/pages/files.vue?macro=true";
import { default as _91id_93L4GE2ngXO2Meta } from "/app/pages/folder/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexwfmqj0GVbMMeta } from "/app/pages/integrations/index.vue?macro=true";
import { default as successaizt86SjvoMeta } from "/app/pages/integrations/success.vue?macro=true";
import { default as indexQCVAIHV8kNMeta } from "/app/pages/invoice-history/index.vue?macro=true";
import { default as local_45editorGuHJ7Gyvn0Meta } from "/app/pages/local-editor.vue?macro=true";
import { default as mail5RIo9NVLqkMeta } from "/app/pages/mail.vue?macro=true";
import { default as indexqn0Gj0KLIjMeta } from "/app/pages/members/index.vue?macro=true";
import { default as not_45foundors3DK4iQRMeta } from "/app/pages/not-found.vue?macro=true";
import { default as failureJdiSKOHmLMMeta } from "/app/pages/pricing/failure.vue?macro=true";
import { default as indexRZymudthiBMeta } from "/app/pages/pricing/index.vue?macro=true";
import { default as successyhmmGAZJMdMeta } from "/app/pages/pricing/success.vue?macro=true";
import { default as _91section_93MYb4k3mPlrMeta } from "/app/pages/settings/[section].vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as _91id_93txii9aFhRiMeta } from "/app/pages/shared/[share_id]/d/[id].vue?macro=true";
import { default as _91id_93civr5AduQRMeta } from "/app/pages/shared/[share_id]/folder/[id].vue?macro=true";
import { default as task_45listrx5nWytIeJMeta } from "/app/pages/task-list.vue?macro=true";
import { default as indexOhLTp7DiVtMeta } from "/app/pages/team/setup/index.vue?macro=true";
import { default as _91id_93AiQgCUQXOpMeta } from "/app/pages/template/[id].vue?macro=true";
import { default as indexbwNKgppt9yMeta } from "/app/pages/usage-limit/index.vue?macro=true";
import { default as indexXwDRoXvyabMeta } from "/app/pages/usage/index.vue?macro=true";
export default [
  {
    name: accesscHBIYVS2zrMeta?.name ?? "auth-access",
    path: accesscHBIYVS2zrMeta?.path ?? "/auth/access",
    meta: accesscHBIYVS2zrMeta || {},
    alias: accesscHBIYVS2zrMeta?.alias || [],
    redirect: accesscHBIYVS2zrMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/access.vue").then(m => m.default || m)
  },
  {
    name: email_45sentIDwxj6WtPGMeta?.name ?? "auth-email-sent",
    path: email_45sentIDwxj6WtPGMeta?.path ?? "/auth/email-sent",
    meta: email_45sentIDwxj6WtPGMeta || {},
    alias: email_45sentIDwxj6WtPGMeta?.alias || [],
    redirect: email_45sentIDwxj6WtPGMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/email-sent.vue").then(m => m.default || m)
  },
  {
    name: errorT0PrdEAY6vMeta?.name ?? "auth-error",
    path: errorT0PrdEAY6vMeta?.path ?? "/auth/error",
    meta: errorT0PrdEAY6vMeta || {},
    alias: errorT0PrdEAY6vMeta?.alias || [],
    redirect: errorT0PrdEAY6vMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordPFs5wuK5FuMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordPFs5wuK5FuMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordPFs5wuK5FuMeta || {},
    alias: forgot_45passwordPFs5wuK5FuMeta?.alias || [],
    redirect: forgot_45passwordPFs5wuK5FuMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: loginikXFF2hTKwMeta?.name ?? "auth-login",
    path: loginikXFF2hTKwMeta?.path ?? "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    alias: loginikXFF2hTKwMeta?.alias || [],
    redirect: loginikXFF2hTKwMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordBuzOWGoMHoMeta?.name ?? "auth-new-password",
    path: new_45passwordBuzOWGoMHoMeta?.path ?? "/auth/new-password",
    meta: new_45passwordBuzOWGoMHoMeta || {},
    alias: new_45passwordBuzOWGoMHoMeta?.alias || [],
    redirect: new_45passwordBuzOWGoMHoMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: registerTCvAsPw3TEMeta?.name ?? "auth-register",
    path: registerTCvAsPw3TEMeta?.path ?? "/auth/register",
    meta: registerTCvAsPw3TEMeta || {},
    alias: registerTCvAsPw3TEMeta?.alias || [],
    redirect: registerTCvAsPw3TEMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: indexD1OagYyPfPMeta?.name ?? "billing",
    path: indexD1OagYyPfPMeta?.path ?? "/billing",
    meta: indexD1OagYyPfPMeta || {},
    alias: indexD1OagYyPfPMeta?.alias || [],
    redirect: indexD1OagYyPfPMeta?.redirect || undefined,
    component: () => import("/app/pages/billing/index.vue").then(m => m.default || m)
  },
  {
    name: calendarsHG7ZbpD8zMeta?.name ?? "calendar",
    path: calendarsHG7ZbpD8zMeta?.path ?? "/calendar",
    meta: calendarsHG7ZbpD8zMeta || {},
    alias: calendarsHG7ZbpD8zMeta?.alias || [],
    redirect: calendarsHG7ZbpD8zMeta?.redirect || undefined,
    component: () => import("/app/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatqOxyUSQXKlMeta?.name ?? "chat",
    path: chatqOxyUSQXKlMeta?.path ?? "/chat",
    meta: chatqOxyUSQXKlMeta || {},
    alias: chatqOxyUSQXKlMeta?.alias || [],
    redirect: chatqOxyUSQXKlMeta?.redirect || undefined,
    component: () => import("/app/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mY4jWfYDC4Meta?.name ?? "d-id",
    path: _91id_93mY4jWfYDC4Meta?.path ?? "/d/:id()",
    meta: _91id_93mY4jWfYDC4Meta || {},
    alias: _91id_93mY4jWfYDC4Meta?.alias || [],
    redirect: _91id_93mY4jWfYDC4Meta?.redirect || undefined,
    component: () => import("/app/pages/d/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnOUxntqq7pMeta?.name ?? "d",
    path: indexnOUxntqq7pMeta?.path ?? "/d",
    meta: indexnOUxntqq7pMeta || {},
    alias: indexnOUxntqq7pMeta?.alias || [],
    redirect: indexnOUxntqq7pMeta?.redirect || undefined,
    component: () => import("/app/pages/d/index.vue").then(m => m.default || m)
  },
  {
    name: filespcJAB5HGY4Meta?.name ?? "files",
    path: filespcJAB5HGY4Meta?.path ?? "/files",
    meta: filespcJAB5HGY4Meta || {},
    alias: filespcJAB5HGY4Meta?.alias || [],
    redirect: filespcJAB5HGY4Meta?.redirect || undefined,
    component: () => import("/app/pages/files.vue").then(m => m.default || m)
  },
  {
    name: _91id_93L4GE2ngXO2Meta?.name ?? "folder-id",
    path: _91id_93L4GE2ngXO2Meta?.path ?? "/folder/:id()",
    meta: _91id_93L4GE2ngXO2Meta || {},
    alias: _91id_93L4GE2ngXO2Meta?.alias || [],
    redirect: _91id_93L4GE2ngXO2Meta?.redirect || undefined,
    component: () => import("/app/pages/folder/[id].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwfmqj0GVbMMeta?.name ?? "integrations",
    path: indexwfmqj0GVbMMeta?.path ?? "/integrations",
    meta: indexwfmqj0GVbMMeta || {},
    alias: indexwfmqj0GVbMMeta?.alias || [],
    redirect: indexwfmqj0GVbMMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: successaizt86SjvoMeta?.name ?? "integrations-success",
    path: successaizt86SjvoMeta?.path ?? "/integrations/success",
    meta: successaizt86SjvoMeta || {},
    alias: successaizt86SjvoMeta?.alias || [],
    redirect: successaizt86SjvoMeta?.redirect || undefined,
    component: () => import("/app/pages/integrations/success.vue").then(m => m.default || m)
  },
  {
    name: indexQCVAIHV8kNMeta?.name ?? "invoice-history",
    path: indexQCVAIHV8kNMeta?.path ?? "/invoice-history",
    meta: indexQCVAIHV8kNMeta || {},
    alias: indexQCVAIHV8kNMeta?.alias || [],
    redirect: indexQCVAIHV8kNMeta?.redirect || undefined,
    component: () => import("/app/pages/invoice-history/index.vue").then(m => m.default || m)
  },
  {
    name: local_45editorGuHJ7Gyvn0Meta?.name ?? "local-editor",
    path: local_45editorGuHJ7Gyvn0Meta?.path ?? "/local-editor",
    meta: local_45editorGuHJ7Gyvn0Meta || {},
    alias: local_45editorGuHJ7Gyvn0Meta?.alias || [],
    redirect: local_45editorGuHJ7Gyvn0Meta?.redirect || undefined,
    component: () => import("/app/pages/local-editor.vue").then(m => m.default || m)
  },
  {
    name: mail5RIo9NVLqkMeta?.name ?? "mail",
    path: mail5RIo9NVLqkMeta?.path ?? "/mail",
    meta: mail5RIo9NVLqkMeta || {},
    alias: mail5RIo9NVLqkMeta?.alias || [],
    redirect: mail5RIo9NVLqkMeta?.redirect || undefined,
    component: () => import("/app/pages/mail.vue").then(m => m.default || m)
  },
  {
    name: indexqn0Gj0KLIjMeta?.name ?? "members",
    path: indexqn0Gj0KLIjMeta?.path ?? "/members",
    meta: indexqn0Gj0KLIjMeta || {},
    alias: indexqn0Gj0KLIjMeta?.alias || [],
    redirect: indexqn0Gj0KLIjMeta?.redirect || undefined,
    component: () => import("/app/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: not_45foundors3DK4iQRMeta?.name ?? "not-found",
    path: not_45foundors3DK4iQRMeta?.path ?? "/not-found",
    meta: not_45foundors3DK4iQRMeta || {},
    alias: not_45foundors3DK4iQRMeta?.alias || [],
    redirect: not_45foundors3DK4iQRMeta?.redirect || undefined,
    component: () => import("/app/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: failureJdiSKOHmLMMeta?.name ?? "pricing-failure",
    path: failureJdiSKOHmLMMeta?.path ?? "/pricing/failure",
    meta: failureJdiSKOHmLMMeta || {},
    alias: failureJdiSKOHmLMMeta?.alias || [],
    redirect: failureJdiSKOHmLMMeta?.redirect || undefined,
    component: () => import("/app/pages/pricing/failure.vue").then(m => m.default || m)
  },
  {
    name: indexRZymudthiBMeta?.name ?? "pricing",
    path: indexRZymudthiBMeta?.path ?? "/pricing",
    meta: indexRZymudthiBMeta || {},
    alias: indexRZymudthiBMeta?.alias || [],
    redirect: indexRZymudthiBMeta?.redirect || undefined,
    component: () => import("/app/pages/pricing/index.vue").then(m => m.default || m)
  },
  {
    name: successyhmmGAZJMdMeta?.name ?? "pricing-success",
    path: successyhmmGAZJMdMeta?.path ?? "/pricing/success",
    meta: successyhmmGAZJMdMeta || {},
    alias: successyhmmGAZJMdMeta?.alias || [],
    redirect: successyhmmGAZJMdMeta?.redirect || undefined,
    component: () => import("/app/pages/pricing/success.vue").then(m => m.default || m)
  },
  {
    name: _91section_93MYb4k3mPlrMeta?.name ?? "settings-section",
    path: _91section_93MYb4k3mPlrMeta?.path ?? "/settings/:section()",
    meta: _91section_93MYb4k3mPlrMeta || {},
    alias: _91section_93MYb4k3mPlrMeta?.alias || [],
    redirect: _91section_93MYb4k3mPlrMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/[section].vue").then(m => m.default || m)
  },
  {
    name: index11eXGkSJBcMeta?.name ?? "settings",
    path: index11eXGkSJBcMeta?.path ?? "/settings",
    meta: index11eXGkSJBcMeta || {},
    alias: index11eXGkSJBcMeta?.alias || [],
    redirect: index11eXGkSJBcMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93txii9aFhRiMeta?.name ?? "shared-share_id-d-id",
    path: _91id_93txii9aFhRiMeta?.path ?? "/shared/:share_id()/d/:id()",
    meta: _91id_93txii9aFhRiMeta || {},
    alias: _91id_93txii9aFhRiMeta?.alias || [],
    redirect: _91id_93txii9aFhRiMeta?.redirect || undefined,
    component: () => import("/app/pages/shared/[share_id]/d/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93civr5AduQRMeta?.name ?? "shared-share_id-folder-id",
    path: _91id_93civr5AduQRMeta?.path ?? "/shared/:share_id()/folder/:id()",
    meta: _91id_93civr5AduQRMeta || {},
    alias: _91id_93civr5AduQRMeta?.alias || [],
    redirect: _91id_93civr5AduQRMeta?.redirect || undefined,
    component: () => import("/app/pages/shared/[share_id]/folder/[id].vue").then(m => m.default || m)
  },
  {
    name: task_45listrx5nWytIeJMeta?.name ?? "task-list",
    path: task_45listrx5nWytIeJMeta?.path ?? "/task-list",
    meta: task_45listrx5nWytIeJMeta || {},
    alias: task_45listrx5nWytIeJMeta?.alias || [],
    redirect: task_45listrx5nWytIeJMeta?.redirect || undefined,
    component: () => import("/app/pages/task-list.vue").then(m => m.default || m)
  },
  {
    name: indexOhLTp7DiVtMeta?.name ?? "team-setup",
    path: indexOhLTp7DiVtMeta?.path ?? "/team/setup",
    meta: indexOhLTp7DiVtMeta || {},
    alias: indexOhLTp7DiVtMeta?.alias || [],
    redirect: indexOhLTp7DiVtMeta?.redirect || undefined,
    component: () => import("/app/pages/team/setup/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93AiQgCUQXOpMeta?.name ?? "template-id",
    path: _91id_93AiQgCUQXOpMeta?.path ?? "/template/:id()",
    meta: _91id_93AiQgCUQXOpMeta || {},
    alias: _91id_93AiQgCUQXOpMeta?.alias || [],
    redirect: _91id_93AiQgCUQXOpMeta?.redirect || undefined,
    component: () => import("/app/pages/template/[id].vue").then(m => m.default || m)
  },
  {
    name: indexbwNKgppt9yMeta?.name ?? "usage-limit",
    path: indexbwNKgppt9yMeta?.path ?? "/usage-limit",
    meta: indexbwNKgppt9yMeta || {},
    alias: indexbwNKgppt9yMeta?.alias || [],
    redirect: indexbwNKgppt9yMeta?.redirect || undefined,
    component: () => import("/app/pages/usage-limit/index.vue").then(m => m.default || m)
  },
  {
    name: indexXwDRoXvyabMeta?.name ?? "usage",
    path: indexXwDRoXvyabMeta?.path ?? "/usage",
    meta: indexXwDRoXvyabMeta || {},
    alias: indexXwDRoXvyabMeta?.alias || [],
    redirect: indexXwDRoXvyabMeta?.redirect || undefined,
    component: () => import("/app/pages/usage/index.vue").then(m => m.default || m)
  }
]