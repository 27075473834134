import { ApiService, QueryParams } from './api.service'
import { IPlan } from './plans.service'
export interface ISubscription {
    id: string
    user: string
    stripeCustomerId: string
    stripeStatus: string
    endsAt: number | null
    startDate: number | null
    lastEventDate: number | null
    sandboxUser: boolean
    subscriptionPlan: string | null
    planId: number | null
    isFailureAttempt: boolean
    totalCredits: number
    topupCredits: number
    subscriptionCredits: number
    planName: string
    interval: string
    planDetails: IPlan | undefined
    cancelInitiate: boolean
}

interface IGetAutoRecharge {
    threshold: number
    topUp: number
    enabled: boolean
}
interface IUpdateAutoRecharge {
    threshold: number
    top_up: number
    enabled: boolean
}

interface IUpdateMonthlyBudget {
    limit: number
    threshold: number
}
interface IGetMonthlyBudgetData {
    limit: number
    emailThreshold: number
}
export interface IUsageData {
    user_id: string
    model_cost: string
    input_tokens: number
    input_token_cost: number
    output_tokens: number
    output_token_cost: number
    total_tokens: number
    total_cost: number
    created_at: string
}

export interface ICancelSubcriptionResponce {
    message: string,
    url: string
}

class SubscriptionService extends ApiService {
    constructor() {
        const runtimeConfig = useRuntimeConfig()
        const { apiUrl } = runtimeConfig.public
        super(`${apiUrl}`)
    }

    public checkoutSession = async (price_id: string) =>
        await this.post('/subscriptions/checkout-session', {
            price_id,
        })

    public cancelSubcription =
        async (): Promise<ICancelSubcriptionResponce> => {
            return this.get('/subscriptions/cancel/')
        }

    public getSubscription = async (): Promise<ISubscription> =>
        await this.get('/subscriptions/fetch-sub/')

    public switchSubscription = async (price_id: string) => {
        await this.post('subscriptions/switch-plan/', {
            price_id,
        })
    }
    public getAutoRechargeSettings = async (): Promise<IGetAutoRecharge> =>
        await this.get('/users/auto_recharge_config/')

    public updateAutoRecharge = async (data: IUpdateAutoRecharge) =>
        await this.put('/users/auto_recharge_config', data)

    public getMonthlyBudgetSettings =
        async (): Promise<IGetMonthlyBudgetData> =>
            await this.get('cost_config/get_monthly_budget/')

    public updateMonthlyBudget = async (data: IUpdateMonthlyBudget) => {
        await this.put('cost_config/set_monthly_budget/', data)
    }
    public getUsageStats = async (
        query: QueryParams
    ): Promise<Record<string, IUsageData[]>> => {
        const response = await this.get('cost_config/usage/', query)
        return response
    }

    public addTopUp = async (amount: number): Promise<{ url: string }> => {
        const response = await this.post('subscriptions/add-topup/', { amount })
        return response
    }

    public changePaymentMethod = async () => {
        return this.get('subscriptions/new-payment-method/')
    }
    public stopCancelSubscription = async () => {
        return this.get('subscriptions/stop-cancel/')
    }
}
export default SubscriptionService
