import {
    AbilityBuilder,
    createMongoAbility,
    MongoAbility,
    MongoQuery,
} from '@casl/ability'
import { User } from './types/auth'
import { ISubscription } from '@/services/subscription.service'
import { Feature, IPlan } from '@/services/plans.service'
import pricingFeatures from '@/utils/helpers/PricingFeatures'

export interface UserWithSubscription extends User {
    subscription?: SubscriptionWithPlan
}

interface SubscriptionWithPlan extends ISubscription {
    planDetails: IPlan | undefined
}

type Actions =
    | 'create'
    | 'read'
    | 'update'
    | 'delete'
    | 'access'
    | 'manage'
    | 'view'

export type Pages =
    | 'Billing'
    | 'Usage'
    | 'InvoiceHistory'
    | 'UsageLimit'
    | 'Pricing'
    | 'Members'

type Features =
    | 'document_review'
    | 'discuss_document'
    | 'draft_documents'
    | 'research_assistant'
    | 'team_collaboration'
    | 'actions'
    | 'task_management'
    | 'document_storage'
    | 'insert_templates'
    | 'multi_doc_review'
    | 'team_billing'
    | 'dictate_everything'
    | 'auto_draft_full_documents'
    | 'external_document_sharing'
    | 'instant_transcribe'
    | 'precedent_manager'
    | 'live_customer_support'
    | '1_on_1_training'

type Subjects =
    | Feature
    | 'Feature'
    | Pages
    | 'Subscription'
    | 'Member'
    | Features
    | 'AutoRecharge'

export type AppAbility = MongoAbility<[Actions, Subjects], MongoQuery>

export default function defineAbilityFor(
    user: UserWithSubscription | null,
    allFeatures: Feature[]
) {
    const { can, build, cannot } = new AbilityBuilder<AppAbility>(
        createMongoAbility
    )

    const subscription = user?.subscription

    if (subscription) {
        let featuresArray: Feature[] = []

        // if user has a subscription plan
        if (
            subscription.planDetails &&
            subscription.planDetails.features &&
            subscription.planDetails.features.length > 0 &&
            subscription.subscriptionPlan
        ) {
            const planFeatures = pricingFeatures.find(
                (plan) => plan.id === subscription.planId
            )
            featuresArray = planFeatures?.features ?? []
        }

        // if user has credits but no subscription plan
        if (
            subscription.totalCredits >= 1 &&
            !subscription.subscriptionPlan &&
            allFeatures?.length > 0
        ) {
            featuresArray = allFeatures ?? []
        }

        if (featuresArray && Array.isArray(featuresArray)) {
            featuresArray.forEach((feature) => {
                if (feature.available) {
                    can('access', feature.id as Features)
                } else {
                    cannot('access', feature.id as Features)
                }
            })
        }
    }

    if (user) {
        // Admin permissions
        if (user.roles.includes('admin')) {
            can('manage', 'Subscription')
            can('manage', 'Members')
            can('manage', 'InvoiceHistory')
            can('manage', 'Usage')
            can('manage', 'UsageLimit')
            can('manage', 'Pricing')
            can('manage', 'AutoRecharge')
        } else if (user.roles.includes('user')) {
            can('view', 'Billing')
        }
    }

    return build()
}
